#login{
    width:100%;
    height:100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   overflow: hidden;
}

#login-controls{
    display:flex;
    flex-direction: column;
}

#login-controls input{
    width: 200px;
}

#login-controls div{
    margin: 10px;
}

.logo{
    flex-grow:1;
    flex-basis: 0;
}

.login-errors{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow:1;
    flex-basis: 0;
    width: 100%;

}

.login-errors div{
    background-color:rgb(117, 10, 10);
    color:white;
    padding:.2em 1em;
    margin: .2em;
    border-radius: .5em;;
}

.actions{
    display:flex;
    flex-direction: column;
    align-items: center;

}

#login button{
    background: white;
    color:black;
    font-size:16px;
    border-radius:10px;
    padding: 10px;
}

#login button:hover{
    background-color:lightblue;
}

#login button:active{
   background-color:rgb(137, 167, 177);
}