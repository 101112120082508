html,body, #root{
  margin:0;
  padding:0;
  outline: none;
  width:100%;
  height:100%;
  background: #222;
  color:white;
  overflow:hidden;
}

input{
  padding: .5em;
  border-radius: .5em;
  outline:none;
  border:none;
}

button{
  padding: 10px;
  color: white;
  background-color: transparent;
  border:none;
}

button:hover{
  cursor:pointer;
}
button.action{
  background-color: blue;
}

button.cancel{
  background-color: gray;;
}

a{
  color:white;
}

a:link{
  color:white;
}

a:visited{
  color:white;
}
a:hover{
  color:gray;
}