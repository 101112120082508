ul.quick-links{
    width: 100%;
    background:'blue';
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:8px;
    overflow: hidden;
}

ul.quick-links li{
    margin: 0;
    padding:0;
    width:20%;
    flex:0 0 auto;
    box-sizing: border-box;
}

ul.quick-links li a{
    color:white;
}

ul.quick-links.buttons li{
    display:block;
    list-style: none;
    margin: 0;
    padding:5px;
    border:1px yellow solid;
    color:yellow;
    width:25%;
    flex:0 0 auto;
    text-align: center;
}