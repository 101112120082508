@keyframes beacon {
    from {background-color: gray; transform: scale(1); opacity:1;}
    to {background-color: yellow; transform: scale(3); opacity:0;}
}

.focus-indicator{
    position:relative;
    width:18px;
    height:18px;
}
  
.focus-indicator-inner{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
 
}

.focus-indicator-static{
    width:4px;
    height:4px;
    border-radius: 2px;
    background-color: white;
}

.focus-indicator-animated{
    width:6px;
    height:6px;
    border-radius: 3px;
    animation-name: beacon;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
