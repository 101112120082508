#dashboard{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
}
#toolbar{
 width: 100%;
 padding: 8px;
 display:flex;
 flex: 0 0 auto;
 background:#333;
 justify-content: flex-end;
 align-items: center;
}

.spacer{
    flex:1 1 auto;
}

#content{
    flex: 1 1 auto;
    width: 100%;
    overflow-x:hidden;
    overflow-y: auto;
}

.toolbar-links a{
    margin: 0 1em;
    text-decoration: none;
}

#toolbar a.selected-route{
    color:gray;
}