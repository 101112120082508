.tabs-container{
    display:flex;
    width:100%;
    height:100%;
}
.tablist{
    flex:0 0 auto;
    border-right: 1px gray solid;
    overflow-x:hidden;
    overflow-y:auto;
}
.tablist l{
    list-style: none;
}

.tabpanel{
    flex: 1 1 auto;
    overflow-x:hidden;
    overflow-y:auto;
}

.reactive-column-container{
    width:100%;
    display:flex;
    flex-direction:row;
}

.reactive-column{
    width:50%;
}

.tabs-list{
    padding:0;
    margin:0;
    min-width: 180px;
}

.tab{
    list-style: none;
    padding:10px 20px;
    border-bottom:1px solid gray;
    cursor:pointer;
    outline:none;
}
.tab:hover{
    background: rgb(23, 23, 23);
}

.selected-tab{
    background:black;
}

@media(max-width:800px){
    .reactive-column-container{
        display:block;
    }
    .reactive-column{
        width:100%;
    }
}