@keyframes pulse {
    from {transform: scale(1);}
    to  {transform: scale(1.2);}
}

.loading-button{

}

.loading-button.active{
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-duration: 300ms;
    animation-direction: alternate;
}