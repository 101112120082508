.live-input-label-container{
    display:flex;
    flex:1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 16px;
    width:'20px'
}

h4.live-input-label{
    flex: 0 0 auto;
    margin:0 2px 4px 2px;
    font-size: 13px;
    font-weight: normal;
    color:lightblue;
}

.live-input{
    display:flex;
    align-items: center;
    flex:1 1 auto;
    align-self: stretch;
    background-color: transparent;
    border: 1px gray solid;
    padding: 5px;
    border-radius: 5px;
    color:white;
    font-size: inherit;
}

.live-input.has-error{
    border-color:rgb(55, 235, 255);
}
.live-input.has-error input{
    color: rgb(55, 235, 255);
}

.live-input input{
    flex:1 1 auto;
    border:none;
    border-radius:0;
    background: none;
    outline:none;
    padding:0;
    margin:0;
    color:white;
    font-size: inherit;
}

.live-input .focus-indicator{
    opacity:0;
    align-self: flex-end;
}
.live-input:focus-within .focus-indicator{
    opacity:1;
}